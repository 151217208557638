export const financingSolution = [
  {
    key: 1,
    imageUrl:
      'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732648603074-1732648603029-Feature.svg',
    title: 'Access Financing Rapidly',
    description: 'Get the financing you need within hours with a simplified compliance process',
  },
  {
    key: 2,
    imageUrl:
      'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732648728098-1732648728062-Feature.svg',
    title: 'Increase Profitability',
    description: 'Increase inventory levels to boost sales and drive higher profits.',
  },
  {
    key: 3,
    imageUrl:
      'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732648885163-1732648885119-binoculars-fill.svg',
    title: 'Explore Growth Opportunities',
    description:
      'Explore new growth opportunities with the financial support needed to expand and thrive.',
  },
  {
    key: 4,
    imageUrl:
      'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732649027570-1732649027536-Feature.svg',
    title: 'Enhance Customer Satisfaction',
    description:
      'Enhance your customer experience by maintaining smooth operations and meeting demand effectively.',
  },
];

export const processFlowSteps = [
  {
    step: 1,
    title: 'Apply for Financing',
    imageUrl:
      'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732615201494-1732615201369-contract%201.svg',
  },
  {
    step: 2,
    title: 'Receive Financing',
    imageUrl:
      'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732649764797-1732649764723-income.svg',
  },
  {
    step: 3,
    title: 'Purchase Inventory',
    imageUrl:
      'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732649961916-1732649961850-cash-flow%201.svg',
  },
  {
    step: 4,
    title: 'Increase Sales',
    imageUrl:
      'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732649804658-1732649804595-grow-up.svg',
  },
  {
    step: 5,
    title: 'Earn Extra Profit',
    imageUrl:
      'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732649706564-1732649706496-investment.svg',
  },
  {
    step: 6,
    title: 'Repay the Amount',
    imageUrl:
      'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732649864252-1732649864090-corporation.svg',
  },
];

export const licensingSections = [
  {
    key: 1,
    imageUrl:
      'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732649383089-1732649382915-SECP.svg',
    title: 'SECP Licensed',
    description: 'Our digital lending services are in compliance with SECP regulations.',
    linkUrl: 'https://www.secp.gov.pk',
    linkText: 'License No: SECP/DRB/66/ESPL/2022-103',
  },
  {
    key: 2,
    imageUrl:
      'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732649485631-1732649485472-Group%20811875.svg',
    title: 'Shariah Compliant',
    description: 'We comprehensively adhere to Shariah principles with all our financial products.',
    linkUrl: 'https://www.usmaniandco.com',
    linkText: 'To learn more, visit www.usmaniandco.com',
  },
];

export const getScreenSize = (breakpoints: Record<string, boolean>): string => {
  const screenSizes = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];
  return screenSizes.find((size) => breakpoints[size]) || 'xs'; // Fallback to 'xs'
};

export const bankImageUrl =
  'https://xstak-pim-stage.s3.us-east-2.amazonaws.com/uploads/1732614690004-1732614689850-Bank%282%29.png';
