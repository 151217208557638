import { Card, Typography, Button, Divider, Space, Image, Row, Col, Grid } from 'antd';
import axios from 'axios';
const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

import './style.scss';

import {
  bankImageUrl,
  financingSolution,
  getScreenSize,
  licensingSections,
  processFlowSteps,
} from './constant';

const FinancingPage: React.FC = () => {
  const breakpoints = useBreakpoint();
  const { lg, md, sm, xl, xs, xxl } = breakpoints;

  const screenSize = getScreenSize(breakpoints);

  const handleButtonClick = async () => {
    try {
      const response = await axios.get(
        'https://creditbook-stage.xstak.com/xstak-credit-book/api/v1/token'
      );
      const accountId = localStorage.getItem('realm');
      const urlFinal = `https://stag-credit.creditbook.pk/auth/${accountId}/${response?.data?.data?.accessToken}`;
      window.location.href = urlFinal;
    } catch (error) {
      console.error('API call error:', error);
    }
  };

  return (
    <div className="financing-main">
      {/* Header Section */}
      <header className="financing-header">
        <Space direction={xl || xxl ? 'horizontal' : 'vertical'} className="financing-header-space">
          <Space
            direction="vertical"
            className={`header-space-title header-space-title-${screenSize}`}
          >
            <div>
              <Text className={`header-space-text header-space-text-${screenSize}`}>
                Unlock the potential of your business with <strong>fast</strong>,{' '}
                <strong>flexible</strong>, and <b>affordable</b> financing.
              </Text>
              <br />
              <Button className="financing-button" type="primary" onClick={handleButtonClick}>
                Apply Now
              </Button>
            </div>
          </Space>
          <Row justify="center">
            <Image
              src={bankImageUrl}
              alt="Bank image"
              preview={false}
              className={`financing-bank-image financing-bank-image-${screenSize}`}
            />
          </Row>
        </Space>
      </header>
      {/* Features Section */}

      <section className="financing-solution">
        <Row justify="center">
          <Text strong className="financing-solution-header">
            Empower your business with seamless financing solutions
          </Text>
        </Row>
        <Row className={`financing-solution-row financing-solution-row-${screenSize}`}>
          {financingSolution.map(({ description, imageUrl, title, key }) => (
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={6}
              xxl={6}
              key={key}
              className={`financing-solution-col financing-solution-col-${screenSize} financing-solution-col-${screenSize}-${key} financing-solution-col-${key}`}
            >
              <Space direction="vertical">
                <Image src={imageUrl} alt={title} width={40} height={40} preview={false} />
                <Space direction="vertical" size={'small'}>
                  <Text strong className="financing-solution-title">
                    {title}
                  </Text>
                  <Text>{description}</Text>
                </Space>
              </Space>
            </Col>
          ))}
        </Row>
      </section>
      {/* Processing Flow section */}
      <Row className="financing-process-flow" justify="center" align="middle">
        <div className="flow-container">
          <div className="center-card">
            <Title level={xl || xxl ? 2 : lg ? 3 : md ? 4 : 5}>QUICK AND EASY PROCESS</Title>
          </div>
          {processFlowSteps.map(({ title, imageUrl, step }) => (
            <Row key={step} className={`step step-${step}`} justify="center" align="middle">
              <Col md={10} lg={10} xl={10} xxl={10} style={{ paddingTop: 8 }}>
                <Image
                  src={imageUrl}
                  alt={title}
                  className={`financing-process-flow-image financing-process-flow-image-${screenSize}`}
                  preview={false}
                />
              </Col>
              <Col md={14} lg={14} xl={14} xxl={14}>
                <Text type="secondary">Step {step}</Text>
                <br />
                <Text strong>{title}</Text>
              </Col>
            </Row>
          ))}
        </div>
      </Row>
      {/* Licensing Section */}
      <Row justify="center" gutter={[24, 24]} className="financing-license">
        <Space
          direction={xl || xxl ? 'horizontal' : 'vertical'}
          size={'large'}
          align={xs || sm ? 'center' : undefined}
          className="financing-license-space"
        >
          {licensingSections.map(({ key, title, imageUrl, description, linkText, linkUrl }) => (
            <Card key={key} className="financing-license-card" bordered={false}>
              <Row justify="center" align="middle" gutter={[16, 16]}>
                <Image
                  preview={false}
                  width={'42.46%'}
                  height={'42.46%'}
                  src={imageUrl}
                  alt={title}
                />
                <Divider className="financing-license-divider" />
                <Title level={3}>{title}</Title>
                <Text className="financing-license-text">{description}</Text>
                <a href={linkUrl} className="financing-license-link">
                  {linkText}
                </a>
              </Row>
            </Card>
          ))}
        </Space>
      </Row>

      {/* "Grow Your Business Today" Section */}
      <footer className="financing-footer">
        <Row align="middle" justify="center">
          <Space direction="vertical" size={32} align="center">
            <Title level={xxl ? 2 : xl || lg ? 3 : md ? 4 : 5}>Grow your business today</Title>
            <Button type="primary" onClick={handleButtonClick} className="financing-button">
              Apply Now
            </Button>
            <div className="financing-footer-border">
              <Text className="financing-footer-border-info">
                For further information please contact: +92 318 0110801
              </Text>
            </div>
          </Space>
        </Row>
      </footer>
    </div>
  );
};
export default FinancingPage;
